<template>
  <div class="container-fluid mt--6 setting-auto-fx">
    <div class="row justify-content-md-center mb-xs-3 mb-xl-3">
      <div class="col-lg-12">
        <div class="card card-stats mb-4 mb-xl-0">
          <div class="card-header position-relative">
            <h3 class="mb-0">Setting Auto FX Service</h3>
          </div>
          <div class="card-body p-4">
            <el-form
              ref="formSetting"
              :model="formSetting"
              :rules="formSettingRoles"
              label-width="120px"
            >
            <fieldset>
                <legend>Service Active</legend> 
               
                 <el-switch
                  v-model="formSetting.serviceactive"
                  active-text="ON"
                  inactive-text="OFF"
                ></el-switch>
      
              </fieldset>
              
               <div v-if="formSetting.serviceactive">
                  <fieldset>
                <legend>Time Service Start</legend> 
                <el-select style="width:80px" v-model="formSettingTime.startTime.hour" placeholder="Select">
                  <el-option
                    v-for="hour in hours"
                    :key="hour"
                    :label="hour.toString().padStart(2, '0')"
                    :value="hour">
                  </el-option>
                </el-select>
                <span class="p-2"></span>
                <el-select style="width:80px" v-model="formSettingTime.startTime.minute" placeholder="Hour">
                  <el-option
                    v-for="minute in minutes"
                    :key="minute"
                    :label="minute.toString().padStart(2, '0')"
                    :value="minute">
                  </el-option>
                </el-select>
                 
      
              </fieldset>
              <fieldset>
                <legend>Time Service End</legend> 
               
                 <el-select style="width:80px" v-model="formSettingTime.endTime.hour" placeholder="Select">
                    <el-option
                      v-for="hour in hours"
                      :key="hour"
                      :label="hour.toString().padStart(2, '0')"
                      :value="hour">
                    </el-option>
                  </el-select>
                  <span class="p-2"></span>
                  <el-select style="width:80px" v-model="formSettingTime.endTime.minute" placeholder="Hour">
                    <el-option
                      v-for="minute in minutes"
                      :key="minute"
                      :label="minute.toString().padStart(2, '0')"
                      :value="minute">
                    </el-option>
                  </el-select>
      
              </fieldset>
              </div> 
              

               <fieldset v-if="!formSetting.serviceactive">
                <legend>Reason</legend>
                  <el-form-item label="Reason Type" prop="reasontype"
                  >
                    <el-select
                      v-model="formSetting.reasontype"
                      placeholder="Please select reason"
                      class="form-control-alternative"
                      @change="getReason"
                    >
                      <el-option
                        label="Maintenance"
                        value="maintenance"
                      ></el-option>
                      <el-option label="Holiday" value="holiday"></el-option>
                      <el-option label="Other" value="other"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    label="Reason"
                    prop="reason"
                    v-if="!formSetting.serviceactive"
                  >
                    <el-input
                      type="textarea"
                      v-model="formSetting.reason"
                      :disabled="formSetting.reasontype === 'other' ? false : true"
                    ></el-input>
                  </el-form-item>
                </fieldset>

              <el-form-item>
                <el-button
                  type="primary"
                  @click="onSubmit"
                  v-loading="submiting"
                  :disabled="submiting"
                  >Save</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <!----><!---->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageApi from "@/api/manage";
export default {
  data() {
    return {
      submiting: false,
      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      minutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
      formSettingTime: {
        "dayOfWeek": [],
        "startTime": {
          "hour": 0,
          "minute": 0
        },
        "endTime": {
          "hour": 0,
          "minute": 0
        },
      },
      formSetting: {
        serviceactive: false,
        reasontype: "",
        reason: "",
        starttime: "09:00",
        endtime: "17:00",
      },
      formSettingRoles: {
        reasontype: [
          {
            required: true,
            message: "Please select reason",
            trigger: "blur",
          },
        ],
        reason: [
          {
            required: true,
            message: "Please enter other reason",
            trigger: "blur",
          },
        ],
      },
      reasonMessage: {
        other: "",
        maintenance: "Service is unavailable during the maintenance time. Please try again letter or contact us at 0203 355 9660",
        holiday: "Service is unavailable during the bank holiday Please used this function between 9.00-17.00 between Monday - Friday",
        closed: "Services is unavailable during the of closed Please used this function between 9.00-17.00 between Monday - Friday",
      },
    };
  },
  methods: {
    getReason(){
      this.formSetting.reason =this.reasonMessage[this.formSetting.reasontype];
    },
    onSubmit() {
      this.$refs.formSetting.validate((valid) => {
        if (valid) {
          this.submiting = true;
          this.formSetting.starttime = `${this.formSettingTime.startTime.hour}:${this.formSettingTime.startTime.minute}`;
          this.formSetting.endtime =  `${this.formSettingTime.endTime.hour}:${this.formSettingTime.endTime.minute}`;
          ManageApi.postSettingAutoFX(this.formSetting).then(
            ({ result, message }) => {
              this.submiting = false;
              if (result) {
                this.$swal(
                  "Success",
                  "Update Active Service Success",
                  "success"
                );
              } else {
                this.$swal("Message", message, "error");
              }
            }
          );
        }
      });
    },
  },
  mounted() {
    ManageApi.getSettingAutoFX().then(({ result, data }) => {
      if (result) {
        this.formSetting.serviceactive = data.serviceactive || false;
        this.formSetting.reasontype = data.reasontype || "";
        this.formSetting.reason = data.reason || "";
        this.formSetting.starttime = data.starttime || "09:00";
        this.formSetting.endtime = data.endtime || "17:00";
        var start = data.starttime.split(":")
        this.formSettingTime.startTime.hour = start[0];
        this.formSettingTime.startTime.minute = start[1];
        var end = data.endtime.split(":")
        this.formSettingTime.endTime.hour = end[0];
        this.formSettingTime.endTime.minute = end[1];
      }
    });
  },
};
</script>

<style lang="scss">
.setting-auto-fx{
  fieldset {
    border: 1px solid rgba(0, 0, 0, 0.07);
    margin: 16px 2px;
    padding: 1.2rem;
  }
  legend {
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 3px 10px;
    font-size: 12px;
    font-weight: bold;
    width: auto;
  }
}
</style>